import Cookie from 'js-cookie'
import { addLocale, useLocale } from 'ttag'

const defaultLocale = 'en'
const currentLocale = Cookie.get('locale') || defaultLocale

if (currentLocale !== 'en') {
  const translationObj = require(`../../../shared/locales/${currentLocale}.po.json`)
  addLocale(currentLocale, translationObj)
  useLocale(currentLocale)
}

export {
  defaultLocale,
  currentLocale
}
