import Cookie from 'js-cookie'

window.components.modals.generic = ({ modalId, autoStart }) => {
  return {
    autoStart: autoStart,
    modalId: modalId,
    visible: false,
    handleResize() {
      this.$el.style.height = `${window.innerHeight}px`
    },
    init() {
      this.handleResize()
      this.handleAutoOpen()
    },
    handleAutoOpen() {
      if (!this.autoStart) return
      if (!Cookie.get(this.modalId)) return

      Cookie.remove(this.modalId)
      this.open({ modalId : this.modalId })
      setTimeout(() => this.close(), 3000)
    },
    open({ modalId }) {
      if (modalId === this.modalId && !this.visible) {
        this.visible = true
        this.$store.overlays.modalOpened()
      }
    },
    close() {
      this.visible = false
      this.$store.overlays.modalClosed()
    }
  }
}
