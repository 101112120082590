import Alpine from 'alpinejs'

Alpine.store('overlays', {
  expanded: false,
  searchExpanded: false,
  modalsCount: 0,
  modalOpened() {
    this.modalsCount++
    if (this.shouldHandleOverscroll()) document.body.classList.add('overflow-hidden')
  },
  modalClosed() {
    this.modalsCount--
    if (this.modalsCount < 0) this.modalsCount = 0
    if (this.modalsCount === 0) document.body.classList.remove('overflow-hidden')
  },
  shouldHandleOverscroll() {
    return !document.body.classList.contains('overscroll-none')
  }
})
