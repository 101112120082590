import Bowser from 'bowser'

const browser = Bowser.getParser(window.navigator.userAgent)
const isValidBrowser = browser.satisfies({
  windows: {
    'internet explorer': '<=11',
  }
})

if (isValidBrowser) {
  window.location.replace('/alerts/browser.html')
}
