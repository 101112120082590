const handleLogin = (loginPath, loginPayload) => {
  if (!loginPath) return

  if (document.getElementById('authentication-form-modal')
    || (document.querySelector('.authentication-form-modal'))) {
    window.components.modal().dispatch('open-authentication-modal', loginPayload)
  }
  else {
    window.location.href = loginPath
  }
}

const addAjaxHeaders = (options) => {
  if (!options['headers']) {
    options['headers'] = {}
  }
  const headers = options['headers']

  headers['X-Requested-With'] = 'XMLHttpRequest'
  headers['Content-Type'] ='application/json'

  if (options['method'] === 'GET') {
    return options
  }

  const xcsrfToken = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
  headers['X-CSRF-Token'] = xcsrfToken

  return options
}

const filterOptions = (options) => {
  const customOptions = {
    skipAuthCheck: false,
    skipFollowRedirect: false,
    loginPayload: {}
  }

  Object.keys(customOptions).forEach((key) => {
    if (options[key]) {
      customOptions[key] = options[key]
      delete options[key]
    }
  })

  return customOptions
}

const enhancedFetch = (url, options) => {
  const { skipAuthCheck, loginPayload, skipFollowRedirect } = filterOptions(options)
  return fetch(url, addAjaxHeaders(options || {}))
    .then((response) => {
      if (!skipAuthCheck && (response.status === 401)) {
        handleLogin(response.headers.get('devise-login-path'), loginPayload)
        throw new Error('Not Authorized')
      }

      if ((response.status === 422) && (response.headers.get('Invalid-CSRF-Token') === 'true')) {
        window.location.reload()
        throw new Error('Invalid CSRF Token')
      }

      if (!skipFollowRedirect && response.redirected) {
        window.location.href = response.url
      }

      return response
    })
}

export default enhancedFetch
