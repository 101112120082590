window.components.formValidation = () => {
  return {
    initForm(el) {
      el.querySelectorAll('input[required]:not([type=checkbox])').forEach(
        (input) => {
          input.addEventListener('input', this.validatePresence)
        }
      )
    },
    validatePresence(event) {
      const input = event.target

      if (input.value === '') {
        input.classList.add('empty')
      } else {
        input.classList.remove('empty')
      }
    }
  }
}