import enhancedFetch from 'compact/shared/general/enhanced_fetch.js'

window.components.modals.customer = ({ apiEndpoint, redirectEndpoint }) => {
  return {
    formValidation: window.components.formValidation(),
    apiEndpoint: apiEndpoint,
    redirectEndpoint: redirectEndpoint,
    contactType: '',
    isOpen: false,
    complete: false,
    name: '',
    email: '',
    phoneNumber: '',
    companyName: '',
    message: '',
    init() {
      this.formValidation.initForm(this.$refs.customerForm)
    },
    open(contactType) {
      if (!this.isOpen) {
        this.isOpen = true
        if (contactType) this.contactType = contactType
        this.$store.overlays.modalOpened()
      }
    },
    close() {
      this.isOpen = false
      this.complete = false
      this.contactType = ''
      this.$store.overlays.modalClosed()
    },
    submit() {
      this.$refs.customerForm.reportValidity()
      if (!this.$refs.customerForm.checkValidity()) return

      enhancedFetch(
        this.apiEndpoint,
        {
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            phone_number: this.phoneNumber,
            company_name: this.companyName,
            message: this.message,
            contact_type: this.contactType
          }),
          method: 'POST',
        }
      ).then((r) => {
        if (r.status !== 500) this.complete = true
      })
    },
    back() {
      this.close()
      window.location = this.redirectEndpoint
    }
  }
}
