import Cookie from 'js-cookie'

import enhancedFetch from './enhanced_fetch.js'

const eventPayload = (eventType, properties) => {
  const urlParams = new URLSearchParams(window.location.search)
  const result = {
    session:    {
      tracking_parameters: {
        campaign: urlParams.get('utm_campaign'),
        term:     urlParams.get('utm_term'),
        content:  urlParams.get('utm_content'),
        medium:   urlParams.get('utm_medium'),
        source:   urlParams.get('utm_source')
      },
      fb_click_id:         Cookie.get('_fbc') || null,
      fb_browser_id:       Cookie.get('_fbp') || null,
      google_click_id:     urlParams.get('gclid'),
      display_click_id:    urlParams.get('dclid')

    },
    url:        window.location.href.replace(/#+$/, ''),
    event_type: eventType,
    event_time: (new Date).toISOString()
  }

  return { ...result, ...properties }
}

const track = (eventType, properties) => {
  enhancedFetch('/events', {
    body: JSON.stringify(eventPayload(eventType, properties)),
    method: 'POST',
    headers: {
      'Accept': 'text/javascript'
    }
  })
}

const trackEvent = (eventType, extraProperties) => {
  if (document.querySelector('body.skip-tracking')) return

  const metaTag = document.querySelector('head meta[name="marketer-analytics"]')
  const defaultProperties = { ...(metaTag && metaTag.dataset) || {} }
  const properties = { ...defaultProperties, ...(extraProperties || {}) }

  Object.keys(properties).map((key) => {
    if (key.endsWith('Id')) {
      properties[key.replace('Id', '_id')] = properties[key]
      delete properties[key]
    }
  })

  track(eventType, properties)
}

document.addEventListener('DOMContentLoaded', () => {
  if (document.querySelector('body.skip-page-tracking')) return

  trackEvent('page_view')

  const elements = [...document.querySelectorAll('[data-track-event]')]
  elements.forEach((element) => {
    element.addEventListener('click', () => trackEvent(element.dataset.trackEvent))
  })
})

export default trackEvent
