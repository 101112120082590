import { reduce, merge } from 'lodash'
import { format } from 'date-fns'
import parseISO from 'date-fns/parseISO'
import { format as priceGroupFormat } from 'd3'

import { dateLocale } from './region'

export const isAndroid = () => {
  return (/Android|Linux/i.test(navigator.userAgent))
}

export const isIOS = () => {
  return (/iPhone|iPad|iPod/i.test(navigator.userAgent))
}

export const isMobile = (strict = false) => {
  if (strict) {
    return window.matchMedia('(max-width: 425px)').matches
  }
  return window.matchMedia('(max-width: 768px)').matches
}

export const queryStringToParams = queryStr => {
  const queryParams = new URLSearchParams(queryStr)

  const params = []
  for(const pair of queryParams.entries()) {
    if (pair[0].indexOf('[') > -1) {
      const key = pair[0].split('[')[0]
      const subkey = pair[0].split('[')[1].split(']')[0]

      params.push({ [key] : { [subkey]: pair[1] } })
    } else {
      params.push({ [pair[0]]: pair[1] })
    }
  }

  return reduce(params, merge) || {}
}

export const extractLocationInfo = (place) => {
  const fields = place.split(',').map(s => s.trim())
  switch (fields.length) {
  case 2:
    return fields[0]
  case 3:
    return [fields[0], fields[1]].join(', ')
  case 4:
    return [fields[0], fields[2]].join(', ')
  default:
    return place
  }
}

export const formatDate = (isoDate) => {
  return format(parseISO(isoDate), 'd. MMMM yyyy', { locale: dateLocale })
}

export const formatPrice = (price) => {
  return price?.match(/^(\S+)\s(.*)/).slice(1)[1].replace(/[^0-9.,]*/, '')
}

export const formatAmount = (amount) => {
  return amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const formatAmountInCents = (amountInCents) => {
  return amountInCents && formatAmount(amountInCents / 100.0)
}

// Format price from 2500000 to 2.5M
export const groupPrice = priceGroupFormat('.2~s')

export const priceToCents = (price) => price && parseFloat(price) * 100
export const centsToPrice = (cents) => cents && parseFloat(cents) / 100

export const filterObjectKeys = (object, keys) => {
  return keys.reduce((obj, key) => ({ ...obj, [key]: object[key] }), {})
}

export const objectsEqual = (firstObject, secondObject, fields) => {
  if (!firstObject && secondObject) return false

  const first = filterObjectKeys(firstObject, fields)
  const second = filterObjectKeys(secondObject, fields)
  return JSON.stringify(first) === JSON.stringify(second)
}

export const ensureArray = function(...args) {
  if (args.length === 0) return []
  if (args.length === 1) {
    if (Array.isArray(args[0])) return args[0]
  }
  return Array.prototype.slice.call(args)
}
