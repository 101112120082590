import Cookie from 'js-cookie'

window.components.consent = () => {
  const CONSENT_COOKIE = 'cookie_consent'

  return {
    signed: true,
    init() {
      this.signed = !!Cookie.get(CONSENT_COOKIE)
    },
    sign() {
      Cookie.set(CONSENT_COOKIE, 'true', { expires: 180 })
      this.signed = true
    }
  }
}
