import Cookie from 'js-cookie'
import nbLocale from 'date-fns/locale/nb'
import enLocale from 'date-fns/locale/en-US'

const international = Cookie.get('international') === '1'
const dateLocale = international ? enLocale : nbLocale
const currentCurrency = international ? '$' : 'kr'
const predefinedCities = [
  {
    label: 'Oslo',
    id: 1,
    coordinates: '59.85443957,10.6526864,59.97319422,10.8518136',
    polygon: '59.982041,10.652686,59.982041,10.851814,59.845559,10.851814,59.845559,10.652686'
  },
  {
    label: 'Trondheim',
    id: 2,
    coordinates: '63.37748197,10.2954864,63.48344007,10.4946136',
    polygon: '63.491333,10.295486,63.491333,10.494614,63.369558,10.494614,63.369558,10.295486'
  },
  {
    label: 'Stavanger',
    id: 3,
    coordinates: '58.90886833,5.6335464,59.03098351,5.8326736',
    polygon: '59.040081,5.633546,59.040081,5.832674,58.899737,5.832674,58.899737,5.633546'
  },
  {
    label: 'Kristiansand',
    id: 4,
    coordinates: '58.0973687,7.9186464,58.22234156,8.1177736',
    polygon: '58.231652,7.918646,58.231652,8.117774,58.088023,8.117774,58.088023,7.918646'
  },
  {
    label: 'Bergen',
    id: 5,
    coordinates: '60.33268607,5.2224864,60.44972875,5.4216136',
    polygon: '60.458448,5.222486,60.458448,5.421614,60.323933,5.421614,60.323933,5.222486'
  },
  {
    label: 'Tromsø',
    id: 6,
    coordinates: '69.60796832,18.8557564,69.69035184,19.0548836',
    polygon: '69.696488,18.855756,69.696488,19.054884,69.601807,19.054884,69.601807,18.855756'
  },
  {
    label: 'Fredrikstad',
    id: 7,
    coordinates: '59.15987302,10.8351364,59.28109932,11.0342636',
    polygon: '59.29013,10.835136,59.29013,11.034264,59.150808,11.034264,59.150808,10.835136'
  },
  {
    label: 'Sandnes',
    id: 8,
    coordinates: '58.79194158,5.6333864,58.91447003,5.8325136',
    polygon: '58.923598,5.633386,58.923598,5.832514,58.782779,5.832514,58.782779,5.633386'
  }
]

const predefinedPolygon = (international)
  ? '82.564946,-142.661469,82.564946,61.244781,-76.472959,61.244781,-76.472959,-142.661469'
  : '71.188619,1.520285,71.188619,27.008566,56.147043,27.008566,56.147043,1.520285'

export {
  international,
  currentCurrency,
  predefinedCities,
  predefinedPolygon,
  dateLocale
}
